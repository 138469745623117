import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-white mt-12" aria-labelledby="footer-heading">
      <hr className="h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50 mb-10" />
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-1 xl:gap-8">
          <Link to="/">
            <h1 className="text-4xl tracking-tighter font-sans font-bold pt-0 bg-size-200 bg-pos-0 cursor-pointer">
              festivals.rsvp
            </h1>
          </Link>
          <div className="mt-12 grid grid-cols-1 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-3xl font-bold text-dark-500 tracking-tighter lowercase">
                  Music Festival Directory
                </h3>
                <ul className="mt-4 space-y-4 m-2 p-2">
                  <li>
                    <a
                      href="https://festivals.rsvp"
                      className="tracking-tight text-xl tracking-tighter text-dark-500 hover:text-primary-500"
                    >
                      All Festivals
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://coachella.rsvp/"
                      className="tracking-tight text-xl tracking-tighter text-dark-500 hover:text-primary-500"
                    >
                      Coachella
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://lollapalooza.rsvp/"
                      className="tracking-tight text-xl tracking-tighter text-dark-500 hover:text-primary-500"
                    >
                      Lollapalooza
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://edm.rsvp/"
                      className="tracking-tight text-xl tracking-tighter text-dark-500 hover:text-primary-500"
                    >
                      Electric Daisy Carnival
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://glastonbury.rsvp/"
                      className="tracking-tight text-xl tracking-tighter text-dark-500 hover:text-primary-500"
                    >
                      Glastonbury
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://tomorrowland.rsvp/"
                      className="tracking-tight text-xl tracking-tighter text-dark-500 hover:text-primary-500"
                    >
                      Tomorrowland
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-2xl font-bold text-dark-500 tracking-tighter lowercase">
                  Info
                </h3>
                <ul className="mt-4 space-y-4 m-2 p-2">
                  <li>
                    <a
                      href="/privacy"
                      className="tracking-tight text-xl tracking-tighter text-dark-500 hover:text-primary-500"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="/terms"
                      className="tracking-tight text-xl tracking-tighter text-dark-500 hover:text-primary-500"
                    >
                      Terms
                    </a>
                  </li>
                  {/* <li>
                  <a href="/contact" className="tracking-tight text-xl tracking-tighter text-dark-500 hover:text-primary-500">Contact Us</a>
                </li> */}
                </ul>
              </div>
              {/* <div className="mt-12 md:mt-0">
              <h3 className="text-2xl font-bold text-dark-500 tracking-tighter lowercase">housepartynetwork</h3>
              <ul className="mt-4 space-y-4 m-2 p-2">
                <li>
                  <a href="https://mtdr.com" className="tracking-tight text-xl tracking-tighter text-dark-500 hover:text-primary-500">mtdr</a>
                </li>
                <li>
                  <a href="https://woolabs.com" className="tracking-tight text-xl tracking-tighter text-dark-500 hover:text-primary-500">woolabs</a>
                </li>
                <li>
                <a href="https://namefence.com" className="text-xl tracking-tighter text-black tracking-tighter top-0">name[fence] domains</a>
                </li>
              </ul>
            </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="text-center pt-10 pb-4">
        <Link to="/">
          <p className="text-2xl font-bold text-dark-500 tracking-tighter lowercase">
            festivals.rsvp | © 2023 All Rights Reserved.
          </p>
        </Link>
{/*         <div className="grid-cols-3 space-x-2 mt-4 pb-2">
          <a
            href="https://namefence.com"
            className="text-md text-black tracking-looser mt-4"
          >
            namefence[domains]
          </a>
          <a
            href="https://mtdr.com"
            className="text-md text-black tracking-looser mt-4"
          >
            mtdr[marketing]
          </a>
          <a
            href="https://woolabs.com"
            className="text-md text-black tracking-looser mt-4"
          >
            woolabs[dev]
          </a>
        </div> */}
      </div>
    </footer>
  );
}

export default Footer;
