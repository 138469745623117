import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper';
import Flyer from "./Flyer";
import { LazyLoadImage } from "react-lazy-load-image-component";
// Import Swiper styles

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import 'swiper/css/autoplay';

import { ElfsightWidget } from "react-elfsight-widget";


function Home({ setFestival, festival, festivals, artists, setSpotifyToken }) {
  const navigate = useNavigate()
  useEffect(() => {
    let match = window.location.href.match(/access_token=([\w-]*)/)
    if (match) {
      setSpotifyToken(match[1])
      let redirect = localStorage.getItem('spotifyRedirect')
      if (redirect) {
        navigate(redirect.replace(/https?:\/\/[^/]*/, ''))
        //
      }
    }
    // eslint-disable-next-line
  }, [])


  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [festivals])
  let unfiltered = artists.slice(0, 4)

  return <div className="text-4xl text-black font-bold tracking-tighter pt-4 font-bold">
{/* <ElfsightWidget widgetID="86502fc5-0ae9-4efc-b6bb-f95966de2e77" /> */}

  {festival} Festival 2023


  <script defer src="https://cdn.commento.io/js/commento.js"></script>
<div id="commento"></div>

  <Swiper 
  
      className="swiper-hero drop-shadow-xl"
      effect={"coverflow"}
      grabCursor={true}
      centeredSlides={true}
      paginationClickable={true}
      autoHeight={true}
      coverflowEffect={{
        rotate: 100,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      pagination={true}

      // modules={[EffectCoverflow, Pagination]}
      modules={[Autoplay]}
      autoplay={{ delay: 4000 }}
      breakpoints={{
          // when window width is >= 1200px
          1200: {
              slidesPerView: 1,
              spaceBetween: 0
          },
          // when window width is >= 640px
          640: {
              slidesPerView: 1,
              spaceBetween: 0
          },
          // when window width is >= 320px
          320: {
              slidesPerView: 1,
              spaceBetween: 0
          }
      }}

    >
     
      {artists.sort((a, b) => b.popularity - b.popularity).slice(0, 20).map((artist, i) => <SwiperSlide key={i}>

        <div className="slide w-auto pt-10 h-96  mt-4 relative rounded-3xl" loading="lazy" style={{backgroundSize: "cover", backgroundPosition: "center", backgroundImage: `url(${artist.image})`}}>
          <div onClick={() => navigate(`/artists/${artist.slug}`)} className="overlay text-5xl rounded-3xl absolute transition-all duration-200 hover:bg-black bg-size-200 bg-pos-0 bg-black cursor-pointer bg-opacity-50  top-0 left-0 right-0 bottom-0  text-white font-bold">
            <div className="p-6">{artist.name}</div>
          </div>
        </div>  

      </SwiperSlide>
      )}

    </Swiper> 
    <div className="text-black lowercase tracking-tighter text-black pt-10">
    
      </div> 
      <div className="m-2">
        {artists !== null && (
          <h2 className="text-3xl tracking-tighter font-bold font-sans p-0 pb-4">
            full lineup
          </h2>
        )}
        <div className="grid grid-cols-2 m-2 lg:grid-cols-4 gap-10  drop-shadow-md outline-2 outline-accent-focus items-center ">
          {artists.map((rec, i) => {
            let { slug, name } = rec;
            return (
              <Link
                to={`/artists/${slug}`}
                key={i}
                className="relative  rounded-3xl  "
              >
                <p className="absolute text-white line-clamp-2 text-xl tracking-tighter  font-bold text-center bottom-5 left-0 right-0 bg-opacity-50 z-10 text-xl drop-shadow-md">
                  {name}
                </p>
                <LazyLoadImage
                  src={rec.image}
                  alt={name}
                  className="w-full aspect-square rounded-3xl scale1"
                />
              </Link>
            );
          })}
        </div>
      </div>

          {/* <div className="text-black gap-x-4 grid-cols-auto gap-1 tracking-tighter rounded-3xl mt-4 space-x-10 m-2">
        
         {artists.filter((a, i) => i % 1 === 0).map((artist, i) => <Link className=" text-2xl text-center tracking-tight font-bold transition-all duration-200 hover:bg-black bg-size-200 bg-pos-0 hover:bg-pos-100 cursor-pointer hover:text-white" to={`/artists/${artist.slug}`}>{artist.name}</Link>)}
        </div>   */}

         {/* {artists.filter((a, i) => i % 1 === 0).map((artist, i) => <Link className="text-2xl text-center tracking-tight font-bold transition-all duration-200 hover:bg-black bg-size-200 bg-pos-0 hover:bg-pos-100 cursor-pointer hover:text-white" to={`/artists/${artist.slug}`}>{artist.name}</Link>)}
        </div>   */}
    {/* <div className="text-black md:max-w-3xl lg:max-w-2xl grid-cols-auto gap-1 tracking-tighter rounded-3xl mt-4 space-x-8 m-2 justify-fit">
         {artists.filter((a, i) => i % 1 === 0).map((artist, i) => <Link className="text-2xl text-center tracking-tight font-bold transition-all duration-200 hover:bg-black bg-size-200 bg-pos-0 hover:bg-pos-100 cursor-pointer hover:text-white" to={`/artists/${artist.slug}`}>{artist.name}</Link>)}
        </div>  */}
  
<div className="text-black pt-5">
      
      <Swiper
      effect={"coverflow"}
      grabCursor={true}
      centeredSlides={false}


      coverflowEffect={{
        rotate: 100,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      pagination={false}
      // modules={[EffectCoverflow, Pagination]}
      // modules={[Autoplay]}
      autoplay={{ delay: 5000 }}
      className="mySwiper rounded-3xl drop-shadow-xl"
      breakpoints={{
        // when window width is >= 640px
        640: {
          width: 640,
          slidesPerView: 1,
        },
        // when window width is >= 768px
        768: {
          width: 768,
          slidesPerView: 1,
        },
      }}
    >
      
      {artists.sort((a, b) => b.popularity - b.popularity).slice(6, 30).map((artist, i) => <SwiperSlide key={i}>
        
        <div className="slide gap-4 w-auto h-96 mt-10 mr-4 relative rounded-3xl  drop-shadow-md" loading="lazy" style={{backgroundSize: "cover", backgroundPosition: "center", backgroundImage: `url(${artist.image})`}}>
          <div onClick={() => navigate(`/artists/${artist.slug}`)} className="overlay text-4xl rounded-3xl absolute bg-black transition-all duration-200 hover:bg-black bg-size-200 bg-pos-0 bg-black cursor-pointer bg-opacity-50  top-0 left-0 right-0 bottom-0 text-white font-bold ">
            <div className="p-6">{artist.name}</div>
          </div>
        </div>  
      </SwiperSlide>
      )}

    </Swiper> 
    {/* <Swiper
      effect={"cube"}
      grabCursor={true}
      centeredSlides={false}
      cubeEffect={{
        shadow: true,
        slideShadows: true,
        shadowOffset: 20,
      }}
      coverflowEffect={{
        rotate: 100,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      pagination={false}
      // modules={[EffectCoverflow, Pagination]}
      modules={[Autoplay]}
      autoplay={{ delay: 4000 }}
      className="mySwiper rounded-3xl drop-shadow-xl"
      breakpoints={{
        // when window width is >= 640px
        640: {
          width: 640,
          slidesPerView: 2,
        },
        // when window width is >= 768px
        768: {
          width: 768,
          slidesPerView: 3,
        },
      }}
    >
      
      {artists.sort((a, b) => b.popularity - b.popularity).slice(21, 100).map((artist, i) => <SwiperSlide key={i}>
        
        <div className="slide gap-4 w-auto h-80 mt-10 mr-4 relative rounded-3xl   drop-shadow-md" loading="lazy" style={{backgroundSize: "cover", backgroundPosition: "center", backgroundImage: `url(${artist.image})`}}>
          <div onClick={() => navigate(`/artists/${artist.slug}`)} className="overlay text-3xl rounded-3xl absolute bg-black bg-opacity-50  top-0 left-0 right-0 bottom-0 transition-all duration-200 hover:bg-black bg-size-200 bg-pos-0 bg-black cursor-pointer text-white font-bold p-6">
            <div className="">{artist.name}</div>
          </div>
        </div>  
      </SwiperSlide>
      )}

    </Swiper>  */}
    {/* <Swiper
      effect={"coverflow"}
      grabCursor={true}
      centeredSlides={false}


      coverflowEffect={{
        rotate: 100,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      pagination={false}
      // modules={[EffectCoverflow, Pagination]}
      modules={[Autoplay]}
      autoplay={{ delay: 6000 }}
      className="mySwiper rounded-3xl"
      breakpoints={{
        // when window width is >= 640px
        640: {
          width: 640,
          slidesPerView: 3,
        },
        // when window width is >= 768px
        768: {
          width: 768,
          slidesPerView: 4,
        },
      }}
    >
      
      {artists.sort((a, b) => b.popularity - b.popularity).slice(100, 0).map((artist, i) => <SwiperSlide key={i}>
        
        <div className="slide gap-4 w-auto h-48 mt-10 mr-4  relative rounded-3xl drop-shadow-md" loading="lazy" style={{backgroundSize: "cover", backgroundPosition: "center", backgroundImage: `url(${artist.image})`}}>
          <div onClick={() => navigate(`/artists/${artist.slug}`)} className="overlay text-xl rounded-3xl absolute bg-black bg-opacity-50  top-0 left-0 right-0 bottom-0 text-white font-bold ">
            <div className="p-4">{artist.name}</div>
          </div>
        </div>  
      </SwiperSlide>
      )}

    </Swiper>  */}
    



  



    {/* <p className="font-bold text-left text-xl mt-10 ">{festivals} Lineup</p> */}
  </div> </div>



  
}


export default Home