function Replay(){
  return <main className="prose dark:prose-invert container">
  <header className="text-center mb-8 mt-20 inset-0">
    <h1 className="text-5xl mb-1">Labs</h1>
    <p className="my-0 text-gray3">single dose video lessons and tutorials</p>
  </header>
  <div className="mx-auto w-24 h-1 my-12 bg-gradient-to-r from-gray5 to-gray4 rounded-full" />
  <ul className="grid-list justify-items-center pl-0">
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/ffmpeg-useful-techniques/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/ffmpeg-useful-techniques/img/featured.webp" alt="Programmatic Video Editing with FFmpeg" />
          <section className="p-5">
            <h5>Programmatic Video Editing with FFmpeg</h5>
            <p className="text-gray3 min-h-[100px]">Useful techniques for editing videos with FFmpeg</p>
            <span className="tag tag-linux">
              #linux
            </span>
          </section>
        </button>
      </article>
    </li>
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/pocketbase-chat-app/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/pocketbase-chat-app/img/featured.webp" alt="Pocketbase Chat App" />
          <section className="p-5">
            <h5>Pocketbase Chat App</h5>
            <p className="text-gray3 min-h-[100px]">Build a chat app with Pocketbase and Svelte</p>
            <span className="tag tag-pocketbase">
              #pocketbase
            </span>
            <span className="tag tag-svelte">
              #svelte
            </span>
          </section>
        </button>
      </article>
    </li>
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/typescript-nodejs-setup/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/typescript-nodejs-setup/img/featured.webp" alt="Setup Node with TypeScript" />
          <section className="p-5">
            <h5>Setup Node with TypeScript</h5>
            <p className="text-gray3 min-h-[100px]">How to configure a new Node.js project with TypeScript and ES Modules</p>
            <span className="tag tag-typescript">
              #typescript
            </span>
            <span className="tag tag-node">
              #node
            </span>
          </section>
        </button>
      </article>
    </li>
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/nuxt-3-firebase/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/nuxt-3-firebase/img/featured.webp" alt="Realtime SSR with Nuxt3 and Firebase" />
          <section className="p-5">
            <h5>Realtime SSR with Nuxt3 and Firebase</h5>
            <p className="text-gray3 min-h-[100px]">How to setup Firebase v9 for SSR and realtime hydration in Nuxt3</p>
            <span className="tag tag-vue">
              #vue
            </span>
            <span className="tag tag-nuxt">
              #nuxt
            </span>
            <span className="tag tag-firebase">
              #firebase
            </span>
          </section>
        </button>
      </article>
    </li>
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/typescript-design-patterns/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/typescript-design-patterns/img/featured.webp" alt="10 Design Patterns in TypeScript" />
          <section className="p-5">
            <h5>10 Design Patterns in TypeScript</h5>
            <p className="text-gray3 min-h-[100px]">A breakdown 10 software design patterns in TypeScript.</p>
            <span className="tag tag-typescript">
              #typescript
            </span>
          </section>
        </button>
      </article>
    </li>
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/code-this-not-that-python-edition/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/code-this-not-that-python-edition/img/featured.webp" alt="Code This, Not That - Python Edition" />
          <section className="p-5">
            <h5>Code This, Not That - Python Edition</h5>
            <p className="text-gray3 min-h-[100px]">10 Tips to make your code more Pythonic.</p>
            <span className="tag tag-python">
              #python
            </span>
          </section>
        </button>
      </article>
    </li>
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/twitter-bot-oauth2-tutorial/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/twitter-bot-oauth2-tutorial/img/featured.webp" alt="Twitter Bot Tutorial" />
          <section className="p-5">
            <h5>Twitter Bot Tutorial</h5>
            <p className="text-gray3 min-h-[100px]">Build a Twitter Bot from scratch using Oauth 2.0 to post tweets on behalf of a user.</p>
            <span className="tag tag-firebase">
              #firebase
            </span>
            <span className="tag tag-cloud-functions">
              #cloud-functions
            </span>
            <span className="tag tag-javascript">
              #javascript
            </span>
          </section>
        </button>
      </article>
    </li>
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/web3-solidity-hardhat-react-tutorial/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/web3-solidity-hardhat-react-tutorial/img/featured.webp" alt="Web3 NFT Tutorial" />
          <section className="p-5">
            <h5>Web3 NFT Tutorial</h5>
            <p className="text-gray3 min-h-[100px]">Mint an NFT with Hardhat and Solidity, then interact with the smart contract using Ethers.js and React.</p>
            <span className="tag tag-web3">
              #web3
            </span>
            <span className="tag tag-solidity">
              #solidity
            </span>
            <span className="tag tag-react">
              #react
            </span>
          </section>
        </button>
      </article>
    </li>
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/redis-nextjs/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/redis-nextjs/img/featured.webp" alt="Fulltext Search with Redis and Next.js" />
          <section className="p-5">
            <h5>Fulltext Search with Redis and Next.js</h5>
            <p className="text-gray3 min-h-[100px]">Build an app capable of fulltext search using Redis and Next.js</p>
            <span className="tag tag-redis">
              #redis
            </span>
            <span className="tag tag-nextjs">
              #nextjs
            </span>
            <span className="tag tag-react">
              #react
            </span>
          </section>
        </button>
      </article>
    </li>
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/firebase-riverpod-flutter/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/firebase-riverpod-flutter/img/featured.webp" alt="Riverpod with Firebase" />
          <section className="p-5">
            <h5>Riverpod with Firebase</h5>
            <p className="text-gray3 min-h-[100px]">Use Flutter Riverpod to manage Firebase auth state and relational realtime data in Firestore</p>
            <span className="tag tag-pro">
              #pro
            </span>
            <span className="tag tag-flutter">
              #flutter
            </span>
            <span className="tag tag-firebase">
              #firebase
            </span>
          </section>
        </button>
      </article>
    </li>
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/node-crypto-examples/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/node-crypto-examples/img/featured.webp" alt="Cryptography Concepts for Node.js Developers" />
          <section className="p-5">
            <h5>Cryptography Concepts for Node.js Developers</h5>
            <p className="text-gray3 min-h-[100px]">Learn 7 essential cryptography concepts in and implement them with Node.js crypto.</p>
            <span className="tag tag-node">
              #node
            </span>
            <span className="tag tag-javascript">
              #javascript
            </span>
            <span className="tag tag-crypto">
              #crypto
            </span>
          </section>
        </button>
      </article>
    </li>
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/api-monetization-stripe/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/api-monetization-stripe/img/featured.webp" alt="API Monetization with Stripe Metered Billing" />
          <section className="p-5">
            <h5>API Monetization with Stripe Metered Billing</h5>
            <p className="text-gray3 min-h-[100px]">Build an API with Express.js and Monetize it with Stripe Metered Billing</p>
            <span className="tag tag-stripe">
              #stripe
            </span>
            <span className="tag tag-node">
              #node
            </span>
            <span className="tag tag-javascript">
              #javascript
            </span>
          </section>
        </button>
      </article>
    </li>
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/tailwind-tutorial/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/tailwind-tutorial/img/featured.webp" alt="Tailwind Tips & Tricks" />
          <section className="p-5">
            <h5>Tailwind Tips &amp; Tricks</h5>
            <p className="text-gray3 min-h-[100px]">Tips &amp; Tricks for learning Tailwind CSS</p>
            <span className="tag tag-css">
              #css
            </span>
            <span className="tag tag-tailwind">
              #tailwind
            </span>
            <span className="tag tag-react">
              #react
            </span>
          </section>
        </button>
      </article>
    </li>
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/react-native-firebase-chat-demo/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/react-native-firebase-chat-demo/img/featured.webp" alt="React Native Firebase Chat Demo" />
          <section className="p-5">
            <h5>React Native Firebase Chat Demo</h5>
            <p className="text-gray3 min-h-[100px]">Build a mobile chat app with React Native, Firebase and Firestore</p>
            <span className="tag tag-pro">
              #pro
            </span>
            <span className="tag tag-react">
              #react
            </span>
            <span className="tag tag-react-native">
              #react-native
            </span>
            <span className="tag tag-firebase">
              #firebase
            </span>
          </section>
        </button>
      </article>
    </li>
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/flutter-firebase-chat-demo/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/flutter-firebase-chat-demo/img/featured.webp" alt="Flutter Firebase Chat Demo" />
          <section className="p-5">
            <h5>Flutter Firebase Chat Demo</h5>
            <p className="text-gray3 min-h-[100px]">Build a chat app with Flutter, Firebase, and Firestore</p>
            <span className="tag tag-pro">
              #pro
            </span>
            <span className="tag tag-flutter">
              #flutter
            </span>
            <span className="tag tag-firebase">
              #firebase
            </span>
          </section>
        </button>
      </article>
    </li>
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/realtime-charts-d3-firebase/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/realtime-charts-d3-firebase/img/featured.webp" alt="Realtime Charts with D3" />
          <section className="p-5">
            <h5>Realtime Charts with D3</h5>
            <p className="text-gray3 min-h-[100px]">Build an animated realtime data visualization with D3 and Firebase</p>
            <span className="tag tag-pro">
              #pro
            </span>
            <span className="tag tag-d3">
              #d3
            </span>
            <span className="tag tag-firebase">
              #firebase
            </span>
            <span className="tag tag-javascript">
              #javascript
            </span>
          </section>
        </button>
      </article>
    </li>
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/framer-motion-advanced-notifications/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/framer-motion-advanced-notifications/img/featured.webp" alt="Animated Notifications with Framer Motion" />
          <section className="p-5">
            <h5>Animated Notifications with Framer Motion</h5>
            <p className="text-gray3 min-h-[100px]">An advanced tutorial using Framer Motion to create animated notifications.</p>
            <span className="tag tag-pro">
              #pro
            </span>
            <span className="tag tag-react">
              #react
            </span>
            <span className="tag tag-animation">
              #animation
            </span>
          </section>
        </button>
      </article>
    </li>
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/framer-motion-modal/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/framer-motion-modal/img/featured.webp" alt="Animated Modals with Framer Motion" />
          <section className="p-5">
            <h5>Animated Modals with Framer Motion</h5>
            <p className="text-gray3 min-h-[100px]">Build an animated modal with Framer Motion and React</p>
            <span className="tag tag-react">
              #react
            </span>
            <span className="tag tag-animation">
              #animation
            </span>
          </section>
        </button>
      </article>
    </li>
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/firebase-v9-migration/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/firebase-v9-migration/img/featured.webp" alt="Firebase v9 Migration" />
          <section className="p-5">
            <h5>Firebase v9 Migration</h5>
            <p className="text-gray3 min-h-[100px]">How to migrate to the new Firebase V9 JavaScript SDK. A complete guide.</p>
            <span className="tag tag-pro">
              #pro
            </span>
            <span className="tag tag-javascript">
              #javascript
            </span>
            <span className="tag tag-firebase">
              #firebase
            </span>
          </section>
        </button>
      </article>
    </li>
    <li className="list-none p-0">
      <article className="max-w-sm overflow-hidden shadow-xl bg-gray6 y-6 hover:scale-105 rounded-xl transition-transform ease-in-out duration-500">
        <button href="/lessons/learn-go-in-100-lines/" className="no-underline">
          <img className="w-full m-0 mb-1 rounded-t-xl" src="https://fire-festivalist.vercel.app/lessons/learn-go-in-100-lines/img/featured.webp" alt="Learn Go in 100 Lines" />
          <section className="p-5">
            <h5>Learn Go in 100 Lines</h5>
            <p className="text-gray3 min-h-[100px]">An introduction to Go</p>
            <span className="tag tag-go">
              #go
            </span>
          </section>
        </button>
      </article>
    </li>
  </ul>
  <ul className="pagination">
    <li className="page-item">
      <button href="/lessons/" className="page-link" aria-label="First"><span aria-hidden="true">««</span></button>
    </li>
    <li className="page-item disabled">
      <button className="page-link" aria-label="Previous"><span aria-hidden="true">«</span></button>
    </li>
    <li className="page-item active"><button className="page-link" href="/lessons/">1</button></li>
    <li className="page-item"><button className="page-link" href="/lessons/page/2/">2</button></li>
    <li className="page-item"><button className="page-link" href="/lessons/page/3/">3</button></li>
    <li className="page-item disabled"><span aria-hidden="true">&nbsp;…&nbsp;</span></li>
    <li className="page-item"><button className="page-link" href="/lessons/page/9/">9</button></li>
    <li className="page-item">
      <button href="/lessons/page/2/" className="page-link" aria-label="Next"><span aria-hidden="true">»</span></button>
    </li>
    <li className="page-item">
      <button href="/lessons/page/9/" className="page-link" aria-label="Last"><span aria-hidden="true">»»</span></button>
    </li>
  </ul>
</main>

}

export default Replay