// import { Link } from "react-router-dom"
// import { useEffect } from "react"



function FestivalList({ festivals, festival, setFestival, user }) {
  return <header className="">
    <div className="flex px-1 py-1 sm:px-3 rounded-xl bg-no-repeat bg-cover text-black ">
    <nav className="grid  transition-all" aria-label="Global">
        </nav>
      <nav className="grid m-auto  mx-auto transition-all border rounded-xl mt-4" aria-label="Global">

 {/* {festivals.map((festivals, i) => <Link onChange={e => setFestival(e.target.value)} className="text-3xl" >{festivals}</Link>)} */}
            
            
            {/* <Link onClick={e => setFestival(e.target.value)} defaultValue={festival} className="select text-black text-xl ml-2">
              {festivals.map((name, i) => <option key={i}>{festivals}</option>)}
            </Link> */}
            

  
      </nav>
    </div>
  </header>

}


export default FestivalList
