import { FaInfoCircle } from "react-icons/fa";
// import SwiperCore, { Navigation, Pagination } from "swiper";

import "swiper/swiper.css";

// SwiperCore.use([Navigation, Pagination]);


function Flyer() {
  const customSwiperParams = {
    observer: true,
    observeParents: true,
    breakpoints: {
      1378: { slidesPerView: 6, slidesPerGroup: 1 },
      998: { slidesPerView: 4, slidesPerGroup: 1 },
      625: { slidesPerView: 3, slidesPerGroup: 1 },
      330: { slidesPerView: 1.5, slidesPerGroup: 1 },
      0: { slidesPerView: 1.5, slidesPerGroup: 1 },
    },
    pagination: true,
    loop: false,
    grabCursor: false,
    draggable: false,
    preventClicksPropagation: true,
    preventClicks: true,
    slideToClickedSlide: false,
    allowTouchMove: true,
  };

  return (
    <section
      className="bg-white bg-no-repeat bg-center bg-cover w-full overflow-hidden h-screen relative"
      style={{
        backgroundImage:
          'url("https://media-prd.coachella.com/content/coachella-2023/images/coa-2023-WEB-4x5-v1.jpg")',
          
      }}
    >
      <div
        className="h-full w-full absolute"
        style={{
          background: "rgb(0,0,0)",
          background:
            "linear-gradient(0deg, rgba(0,0,0,0.8099614845938375) 0%, rgba(255,255,255,0) 100%)",
        }}
      ></div>
      <div className="h-full absolute z-10 w-full">
        <div className="p-6 space-y-2 md:w-[500px] w-full h-full text-white flex items-center ">
          <div className="">
            <p className="xl:text-3xl lg:text-left text-5xl lowercase font-bold mb-3 tracking-wide">
              APR 14-16 & 21-23
            </p>
            <p className="xl:text-2xl lg:text-left text-xl font-bold mb-3 tracking-wide pt-5">
              Discover the Mirage
    A new discovery reveals a more expansive Coachella. In 2023, learn how to interact with the Mirage and unlock new festival creativity on the fields and beyond.
            </p>
            <div className="flex items-left space-x-2 my-5 justify-center md:justify-start pt-5">
              {/* <Link href={`/play/VUuh5Rqh_HA`}>
                <div className="w-40 py-2 bg-red-500 rounded flex items-center justify-center space-x-2 hover:scale-105">
                  <FaPlay size={12} />
                  <p>Play</p>
                </div>
              </Link> */}
              {/* <button className="w-40 py-2 bg-slate-500 bg-opacity-50 rounded flex items-center justify-center space-x-2 hover:scale-105">
                <FaInfoCircle size={12} />
                <p>More Info</p>
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Flyer;
