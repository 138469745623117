import { FaTrash } from "react-icons/fa"
import { useRef, useState } from "react"
import { Link } from "react-router-dom"
import { compareLink } from "../utils"

function CompareBox({ allArtists, comparing, setComparing, toggleCompare }) {
  let [suggestions, setSuggestions] = useState([])
  let searchRef = useRef()
  let names = allArtists.map(a => a.name)

  return <div className="z-10 fixed right-2 top-10 w-96 bg-white rounded-xl p-4 border-2 border-black">
    <p className="font-bold text-xl">Compare</p>
    <div className="relative">
      <input disabled={comparing.length > 3} ref={searchRef} onChange={(e) => setSuggestions(names.filter(p => !comparing.includes(p) && p.toLowerCase().includes(e.target.value.toLowerCase())).slice(0, 5))} type="text" className="input w-full rounded shadow shadow-gray-500 p-2 my-3" placeholder="Search" />
      {suggestions.length > 0 && <div className="absolute bg-green-100 border border-black p-2 px-6 left-4 top-16">
        {suggestions.map((s, i) => <div onClick={() => {
          toggleCompare(s)
          setSuggestions([])
          searchRef.current.value = ""
        }} className="p-1 cursor-pointer">{s}</div>)}
      </div>}
    </div>
    {comparing.map((name, i) => <div onClick={() => toggleCompare(name)}
      key={i} className="p-2 border-t-2 border-green-300 flex items-center space-x-2 cursor-pointer">
      <FaTrash />
      <p>{name}</p>
    </div>)}
    <div className="flex">
      <div className="flex-1" />
      <button onClick={() => setComparing([])} className="font-bold flex items-center space-x-2">
        <FaTrash />
        <p>Remove All</p>
      </button>
    </div>
    <Link to={compareLink(comparing)} onClick={() => setComparing([])} className="btn btn-primary p-2 text-center w-full my-2" disabled={comparing.length < 2}>Compare Now</Link>
  </div>
}

export default CompareBox