
function Privacy() {
	return (
		<main className="min-h-[80vh]">
			<div className="px-4 py-16 sm:px-6 sm:py- lg:px-8">
				<div className="mx-auto prose prose-xl px-8">
					<h1 className="relative mx-auto text-4xl lg:text-6xl font-bold pb-5">
						Privacy Policy
					</h1>
					<h2 className="text-2xl font-bold pb-2 pt-6">Overview</h2>
					<p>
						HousePartyNetwork (HousePartyNetwork, “we”, “us” or “our”) is committed to
						protecting the privacy of personal information (i.e., any
						information relating to an identified or identifiable natural
						person) who visit the HousePartyNetwork website (the “Site”). Amendments to
						this Privacy Policy will be posted to the Site and will be effective
						when posted. Your continued use of the Site following the posting of
						any amendment to this Privacy Policy shall constitute your
						acceptance of such amendment.
					</p>

					<h2 className="text-2xl font-bold pb-2 pt-6">Your consent</h2>
					<p>
						You might fill out a contact form on the Site and provide your name,
						email address, as well as any additional information you may provide
						us in the message box. By providing personal information to us, you
						voluntarily consent to the collection, use and disclosure of such
						personal information as specified in this Privacy Policy. Without
						such information, we could not provide you with our services. The
						legal basis for our processing of personal information are primarily
						that the processing is necessary for providing our services or
						answer to your request (i.e. for the performance of a contract
						between you and us), and that the processing is carried out in our
						legitimate interests, which are further explained below. Without
						limiting the foregoing, we may on occasion ask you to consent when
						we collect, use, or disclose your personal information in specific
						circumstances.
					</p>

					<h2 className="text-2xl font-bold pb-2 pt-6">Information we collect about you</h2>
					<p>We collect several types of information:</p>
					<p>
						Personal Data. We collect information by which you may be personally
						identified, such as your name, email address, postal address or
						phone number, through the contact form on our Site (“personal
						data”). This information is only collected when you voluntarily
						provide it and it is necessary to contact you.
					</p>
					<p>
						Usage Data. We also collect information about your use of the Site.
						This information includes things such as your operating system, your
						access times, the location from which you accessed the Site, the
						time you spent on the Site, and the pages on our Site that you
						visited (“usage data”). We collect this information using cookies,
						web beacons and Google Analytics. A cookie is a small file placed on
						the hard drive of your computer. Unless you have adjusted your
						browser setting so that it will refuse cookies, our system will
						issue cookies when you direct your browser to the Site. A web beacon
						is a small electronic file (also referred to as clear gifs, pixel
						tags, and single-pixel gifs) that permit us, for example, to count
						users who have visited a page and for other related Site statistics
						(e.g., recording the popularity of certain website content and
						verifying system and server integrity).
					</p>
					<h2 className="text-2xl font-bold pb-2 pt-6">Use of personal information</h2>
					<p>
						We may use or disclose your personal information for the following
						purposes:
					</p>
					<ul>
						<li>
							To fulfill or meet the reason you provided the information.
							Applicable legal basis under the General Data Protection
							Regulation (“GDPR”): performance of a contract;
						</li>
						<li>
							To provide the Site to you and to other users. Applicable legal
							basis under the GDPR: performance of a contract;
						</li>
						<li>
							To improve the quality of the Site through polls, surveys and
							other similar feedback gathering activities conducted by HousePartyNetwork
							and/or third parties. Applicable legal basis under the GDPR:
							legitimate interest;
						</li>
						<li>
							To communicate with you, including providing you with information
							about our services. Applicable legal basis under the GDPR:
							legitimate interest;
						</li>
						<li>
							To monitor traffic patterns and gauge popularity of different
							features of the Site. Applicable legal basis under the GDPR:
							legitimate interest;
						</li>
						<li>
							To enforce this Privacy Policy. Applicable legal basis under the
							GDPR: performance of a contract;
						</li>
						<li>
							To protect against fraud or error, and to respond to claims of any
							violation of our rights or those of any third parties. Applicable
							legal basis under the GDPR: legitimate interest;
						</li>
						<li>
							To respond to your requests for customer service. Applicable legal
							basis under the GDPR: performance of a contract;
						</li>
						<li>
							To protect the rights, property or personal safety of you, us, our
							users and the public;
						</li>
						<li>
							As described to you when collecting your personal information.
							Applicable legal basis under the GDPR: performance of a contract;
							and
						</li>
						<li>
							As required to comply with applicable laws or as authorized by
							applicable laws. Applicable legal basis under the GDPR: legal
							obligation.
						</li>
					</ul>

					<h2 className="text-2xl font-bold pb-2 pt-6">Aggregated data</h2>
					<p>
						We may also use your personal information to generate Aggregated
						Data for internal use and for sharing with others on a selective
						basis. “Aggregated Data” means records which have been stripped of
						information potentially identifying users, and which have been
						manipulated or combined to provide generalized, anonymous
						information. Your identity and personal information will be kept
						anonymous in Aggregated Data.
					</p>

					<h2 className="text-2xl font-bold pb-2 pt-6">Storage of personal information</h2>
					<p>
						Your personal information may be stored and processed in any country
						where we have facilities or in which we engage third party service
						providers, notably in Canada and United States. By using the Site,
						you consent to the transfer of information to countries outside your
						country of residence, which may have different data protection rules
						than in your country. While such information is outside of Canada,
						it is subject to the laws of the country in which it is held, and
						may be subject to disclosure to the governments, courts or law
						enforcement or regulatory agencies of such other country, pursuant
						to the laws of such country. However, our practices regarding your
						personal information will at all times continue to be governed by
						this Privacy Policy and, if applicable, we will comply with the
						General Data Protection Regulation (“GDPR”) requirements providing
						adequate protection for the transfer of personal information from
						the EU/EEA to third country, notably by entering into standard
						contractual clauses.
					</p>
					<p>
						We may occasionally communicate with you regarding our products,
						services, news, jobs, and events. You have the option to not receive
						this information. We provide an opt-out function within all email
						communications of this nature, or will cease to communicate with you
						for this purpose if you contact us and tell us not to communicate
						this information to you.
					</p>

					<h2 className="text-2xl font-bold pb-2 pt-6">Age of consent</h2>
					<p>
						We do not knowingly provide the Site to and will not knowingly
						collect the personal information from anyone under the age of
						consent. If you live in a country in the EU/EEA, you must be at
						least 16 years old to use the Site or such greater age required in
						your country to register for or use the Site. If you live in any
						other country except those in the EU/EEA, you must be at least 13
						years old to use the Site or such greater age required in your
						country to register for or use the Site. If you are under 13, do not
						use or provide any information to, on or through the Site, make any
						purchases through the Site, use any of the interactive or public
						comment features, or provide any information about yourself to us,
						including your name, address, telephone number, email address, or
						any screen name or user name you may use. In addition to being of
						the minimum required age to use the Site under applicable law, if
						you are not old enough to have authority to agree to our Privacy
						Policy in your country, your parent or guardian must agree to our
						Privacy Policy on your behalf. If we learn we have collected or
						received personal information from a child under 13 without
						verification of parental consent, we will delete that information.
						If you believe we might have any information from or about a child
						under 13 or if you have any concerns about your child’s personal
						information, please contact us at the email in the “Contact Us”
						section below.
					</p>

					<h2 className="text-2xl font-bold pb-2 pt-6">Opting out of communications</h2>
					<p>
						If you no longer want to receive marketing-related emails from us,
						you may opt-out of receiving marketing-related emails by clicking
						the “unsubscribe” link at the bottom of any email you receive from
						us. You may also opt-out by contacting us directly using the contact
						information in the “Contact Us” section below.
					</p>
					<p>
						We will endeavour to respond to your opt-out request promptly, but
						we ask that you please allow us a reasonable time to process your
						request. Please note that if you opt-out from receiving
						marketing-related emails, we may still need to send you
						communications about your use of our products or services, or other
						matters.
					</p>

					<h2 className="text-2xl font-bold pb-2 pt-6">Rights to your information</h2>
					<p>
						On written request and subject to proof of identity, you may access
						the personal information that we hold, used or communicated and ask
						that any necessary corrections be made, where applicable, as
						authorized or required by law. However, to make sure that the
						personal information we maintain about you is accurate and up to
						date, please inform us immediately of any change in your personal
						information by mail or email.
					</p>
					<p>
						Under the GDPR, you may be entitled to additional rights, including:
						(i) the right to withdraw consent to processing where consent is the
						basis of processing; (ii) the right to access your personal
						information and certain other supplementary information, under
						certain conditions; (iii) the right to object to unlawful data
						processing, under certain conditions; (iv) the right to erasure of
						personal information about you, under certain conditions; (v) the
						right to demand that we restrict processing of your personal
						information, under certain conditions, if you believe we have
						exceeded the legitimate basis for processing, processing is no
						longer necessary, are processing, or believe your personal
						information is inaccurate; (vi) the right to data portability of
						personal information concerning you that you provided us in a
						structured, commonly used, and machine-readable format, under
						certain conditions; (vii) the right to object to decisions being
						taken by automated means which produce legal effects concerning you
						or similarly significantly affect you, under certain conditions;
						(viii) the right to lodge a complaint with data protection
						authorities.
					</p>

					<h2 className="text-2xl font-bold pb-2 pt-6">Links</h2>
					<p>
						The Site may contain links to other sites and we are not responsible
						for the privacy practices or the content of such sites. We encourage
						you to read the privacy policy of linked sites. Their privacy
						policies and practices differ from our policies and practices.
					</p>

					<h2 className="text-2xl font-bold pb-2 pt-6">Third parties’ ads &amp; interest-based ads</h2>
					<p>
						We do not control third parties’ collection or use of your
						information to serve interest-based advertising. However, these
						third parties may provide you with ways to choose not to have your
						information collected or used in this way. You can opt out of
						receiving targeted ads from members of the Network Advertising
						Initiative (“NAI”) on the NAI’s website.
					</p>

					<h2 className="text-2xl font-bold pb-2 pt-6">Security</h2>
					<p>
						We will strive to prevent unauthorized access to your personal
						information, however, no data transmission over the Internet, by
						wireless device or over the air is guaranteed to be 100% secure. We
						have implemented and maintain reasonable security procedures and
						practices (based on the nature of the information we collect) to
						protect that information from unauthorized disclosure. We will
						continue to enhance security procedures as new technologies and
						procedures become available. We take steps designed to ensure that
						only those employees who need access to your personal information to
						fulfil their employment duties will have access to it.
					</p>
					<p>
						Please remember that you control what personal information you
						provide while using the Site. Ultimately, you are responsible for
						maintaining the secrecy of your identification and/or any personal
						information in your possession for the use of the Site. Always be
						careful and responsible regarding your personal information. We
						cannot guarantee, or assume any responsibility for verifying, the
						accuracy of the personal information or other information provided
						by any third party. You release us from any and all liability in
						connection with the use of such personal information or other
						information of others.
					</p>

					<h2 className="text-2xl font-bold pb-2 pt-6">Retention of personal information</h2>
					<p>
						We will maintain your personal information for as long as it is
						needed, to fulfill the purposes for which that personal information
						was collected and as permitted or as required by applicable laws,
						regulations, or government orders. If you would like more
						information on the periods during which your personal information
						will be kept, please contact us using the contact details in the
						“Contact Us” section below.
					</p>

					<h2 className="text-2xl font-bold pb-2 pt-6">Changes to this policy</h2>
					<p>
						We may update this Privacy Policy from time to time. If we do so, we
						will send an email to users subscribed to our mailing list. We will
						also add a site banner alerting users who may not be subscribed. If
						the change materially affects the treatment of your personal data,
						and we have your email but you are not subscribed to the Company
						News list, we will send you an email. (You are responsible for
						ensuring that we have an up-to-date email for this purpose.)
					</p>

					<h2 className="text-2xl font-bold pb-2 pt-6">Contact us</h2>
					<p>
						If you have any questions or comments about this Privacy Policy or
						your personal information, to make an access or correction request,
						to exercise any applicable rights, to make a complaint, or to obtain
						information about our policies and practices with respect to any
						service providers outside United States, our Privacy Executive can
						be reached by email using the following contact information:
						privacy@ourapp.com
					</p>
				</div>
			</div>
		</main>
	);
};

export default Privacy;
