
import { useState, useRef } from "react"
import toast, { Toaster } from "react-hot-toast";

export default function Contact() {
  let formRef = useRef()

  const [data, setData] = useState({})
  const valid = {
    name: data.name && data.name.match(/\w\s+\w/),
    email: data.email && data.email.match(/\S@\S/),
    message: data.message && data.message.length > 5,
  }

  function change(e) {
    setData(Object.fromEntries(new FormData(formRef.current)))
  }

  function submit(e) {
    e.preventDefault()
    let data = Object.fromEntries(new FormData(e.target))
    // alert(JSON.stringify(data))
    if(!data.email.match(/@/)) {
      return toast.error("Invalid Email")
    }

    if(!data.name.match(/\w\s+\w/)) {
      return toast.error("Name is invalid")
    }

    // alert(JSON.stringify(data))
    fetch(`/api/contact`, {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify(data)
    }).then((data) => {
      toast.success("Thanks for contacting us!")
      // router.push("/login")
    })

  }

  return <div className="max-w-screen mx-4 px-5">
    <div className="text-center">
    <h1 className="text-5xl lg:text-4xl xl:text-4xl text-center font-bold lg:tracking-tight xl:tracking-tighter mt-10">
        Contact Us
      </h1>
      <p className="text-lg mt-4 text-slate-600">
        You have questions and problems, we have answers and solutions.
      </p>
    </div><div className="grid md:grid-cols-2 gap-4 mx-auto max-w-4xl mt-16">
      <div>
        <h2 className="font-medium text-2xl text-gray-800">Contact Namefence</h2>
        <p className="text-lg leading-relaxed text-slate-500 mt-3">
          Complete the form or email us directly, and we will be in touch within 2 business days!
        </p>
        <div className="mt-5">
          {/* <div className="flex items-center mt-2 space-x-2 text-gray-600">
            <svg viewBox="0 0 24 24" className="text-gray-400 w-4 h-4" astro-icon="uil:map-marker"><path fill="currentColor" d="M12 2a8 8 0 0 0-8 8c0 5.4 7.05 11.5 7.35 11.76a1 1 0 0 0 1.3 0C13 21.5 20 15.4 20 10a8 8 0 0 0-8-8zm0 17.65c-2.13-2-6-6.31-6-9.65a6 6 0 0 1 12 0c0 3.34-3.87 7.66-6 9.65zM12 6a4 4 0 1 0 4 4 4 4 0 0 0-4-4zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2z" /></svg>
            <span>1734 Sanfransico, CA 93063</span>
          </div> */}
          <div className="flex items-center mt-2 space-x-2 text-gray-600">
            <svg viewBox="0 0 24 24" className="text-gray-400 w-4 h-4" astro-icon="uil:envelope"><path fill="currentColor" d="M19 4H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zm-.41 2-5.88 5.88a1 1 0 0 1-1.42 0L5.41 6zM20 17a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V7.41l5.88 5.88a3 3 0 0 0 4.24 0L20 7.41z" /></svg><a href="mailto:contact@festivals.rsvp">contact@festivals.rsvp</a>
          </div>
          <div className="flex items-center mt-2 space-x-2 text-gray-600">
            <svg viewBox="0 0 24 24" className="text-gray-400 w-4 h-4" astro-icon="uil:phone"><path fill="currentColor" d="M19.44 13c-.22 0-.45-.07-.67-.12a9.44 9.44 0 0 1-1.31-.39 2 2 0 0 0-2.48 1l-.22.45a12.18 12.18 0 0 1-2.66-2 12.18 12.18 0 0 1-2-2.66l.42-.28a2 2 0 0 0 1-2.48 10.33 10.33 0 0 1-.39-1.31c-.05-.22-.09-.45-.12-.68a3 3 0 0 0-3-2.49h-3a3 3 0 0 0-3 3.41 19 19 0 0 0 16.52 16.46h.38a3 3 0 0 0 2-.76 3 3 0 0 0 1-2.25v-3a3 3 0 0 0-2.47-2.9zm.5 6a1 1 0 0 1-.34.75 1.05 1.05 0 0 1-.82.25A17 17 0 0 1 4.07 5.22a1.09 1.09 0 0 1 .25-.82 1 1 0 0 1 .75-.34h3a1 1 0 0 1 1 .79q.06.41.15.81a11.12 11.12 0 0 0 .46 1.55l-1.4.65a1 1 0 0 0-.49 1.33 14.49 14.49 0 0 0 7 7 1 1 0 0 0 .76 0 1 1 0 0 0 .57-.52l.62-1.4a13.69 13.69 0 0 0 1.58.46q.4.09.81.15a1 1 0 0 1 .79 1z" /></svg><a href="tel:+1 (987) 4587 899">+1 (987) 4587 899</a>
          </div>
        </div>
      </div>
      <div>
        <form ref={formRef} onChange={change} onSubmit={submit} action="https://api.web3forms.com/submit" method="POST" id="form" className="needs-validation" noValidate>
          <div className="mb-5">
            <input type="text" placeholder="Full Name" required className="w-full px-4 py-3 border-2 placeholder:text-gray-800 rounded-md outline-none focus:ring-4 border-gray-300 focus:border-gray-600 ring-gray-100" name="name" />
            {!valid.name && <div className="empty-feedback invalid-feedback text-slate-400 text-sm mt-1">
              Please provide your full name.
            </div>}
          </div>
          <div className="mb-5">
            <label htmlFor="email_address" className="sr-only">Email Address</label>
            <input id="email_address" type="email" placeholder="Email Address" name="email" required className="w-full px-4 py-3 border-2 placeholder:text-gray-800 rounded-md outline-none focus:ring-4 border-gray-300 focus:border-gray-600 ring-gray-100" />
            {!valid.email && <div className="empty-feedback text-slate-400 text-sm mt-1">
              Please provide a valid email address.
            </div>}
          </div>
          {/* <div className="mb-3">
            <textarea name="message" required placeholder="Your Message" className="w-full px-4 py-3 border-2 placeholder:text-gray-800 rounded-md outline-none h-36 focus:ring-4 border-gray-300 focus:border-gray-600 ring-gray-100" defaultValue={""} />
            {!valid.message && <div className="empty-feedback invalid-feedback text-slate-400 text-sm mt-1">
              Please enter your message.
            </div>}
          </div> */}
          <button type="submit" className="rounded text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 w-full px-6 py-3 bg-black text-white hover:bg-slate-900 border-2 border-transparent">Send Message
          </button>
          <div id="result" className="mt-3 text-center" />
        </form>
      </div>
    </div>
    <div className="grid grid-cols-auto items-center md:grid-cols-3 mt-16 gap-16 m-4 mt-10">
        <div className="flex gap-6 items-center">

          <div className="mt-1 bg-slate-800 rounded-full  p-3 w-16 h-16 shrink-0">
            <svg viewBox="0 0 24 24" className="text-white" astro-icon="bx:bxs-briefcase"><path fill="currentColor" d="M20 6h-3V4c0-1.103-.897-2-2-2H9c-1.103 0-2 .897-2 2v2H4c-1.103 0-2 .897-2 2v3h20V8c0-1.103-.897-2-2-2zM9 4h6v2H9V4zm5 10h-4v-2H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-8v2z" /></svg>
          </div>
          <div>
            <h3 className="font-semibold text-2xl">Brand and Trademark Registration</h3>
            {/* <p className="text-slate-500 mt-2 leading-relaxed">Build your site using React, Svelte, Vue, Preact, web components, or just plain ol&apos; HTML + JavaScript.</p> */}
          </div>
        </div><div className="flex gap-4 items-center">
          <div className="mt-1 bg-slate-800 rounded-full  p-3 w-16 h-16 shrink-0">
            <svg viewBox="0 0 24 24" className="text-white" astro-icon="bx:bxs-window-alt"><path fill="currentColor" d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zm-3 3h2v2h-2V6zm-3 0h2v2h-2V6zM4 19v-9h16.001l.001 9H4z" /></svg>
          </div>
          <div>
            <h3 className="font-semibold text-2xl">Business and Web Services</h3>
            {/* <p className="text-slate-500 mt-2 leading-relaxed">Astro renders your entire page to static HTML, removing all JavaScript from your final build by default.</p> */}
          </div>
        </div><div className="flex gap-4 items-center">
          <div className="mt-1 bg-slate-800 rounded-full  p-3 w-16 h-16 shrink-0">
            <svg viewBox="0 0 24 24" className="text-white" astro-icon="bx:bxs-data"><path fill="currentColor" d="M20 6c0-2.168-3.663-4-8-4S4 3.832 4 6v2c0 2.168 3.663 4 8 4s8-1.832 8-4V6zm-8 13c-4.337 0-8-1.832-8-4v3c0 2.168 3.663 4 8 4s8-1.832 8-4v-3c0 2.168-3.663 4-8 4z" /><path fill="currentColor" d="M20 10c0 2.168-3.663 4-8 4s-8-1.832-8-4v3c0 2.168 3.663 4 8 4s8-1.832 8-4v-3z" /></svg>
          </div>
          <div>
            <h3 className="font-semibold text-2xl">Complete Analytics Across All Domains</h3>
            {/* <p className="text-slate-500 mt-2 leading-relaxed">Need some JS? Astro can automatically hydrate interactive components when they become visible on the page.</p> */}
          </div>
        </div><div className="flex gap-4 items-center">
          <div className="mt-1 bg-slate-800 rounded-full p-3 w-16 h-16 shrink-0">
            <svg viewBox="0 0 24 24" className="text-white" astro-icon="bx:bxs-bot"><path fill="currentColor" d="M21 10.975V8a2 2 0 0 0-2-2h-6V4.688c.305-.274.5-.668.5-1.11a1.5 1.5 0 0 0-3 0c0 .442.195.836.5 1.11V6H5a2 2 0 0 0-2 2v2.998l-.072.005A.999.999 0 0 0 2 12v2a1 1 0 0 0 1 1v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5a1 1 0 0 0 1-1v-1.938a1.004 1.004 0 0 0-.072-.455c-.202-.488-.635-.605-.928-.632zM7 12c0-1.104.672-2 1.5-2s1.5.896 1.5 2-.672 2-1.5 2S7 13.104 7 12zm8.998 6c-1.001-.003-7.997 0-7.998 0v-2s7.001-.002 8.002 0l-.004 2zm-.498-4c-.828 0-1.5-.896-1.5-2s.672-2 1.5-2 1.5.896 1.5 2-.672 2-1.5 2z" /></svg>
          </div>
          <div>
            <h3 className="font-semibold text-2xl">Buy and Sell Premium Domains</h3>
            {/* <p className="text-slate-500 mt-2 leading-relaxed">Astro supports TypeScript, Scoped CSS, CSS Modules, Sass, Tailwind, Markdown, MDX, and any other npm packages.</p> */}
          </div>
        </div><div className="flex gap-4 items-center">
          <div className="mt-1 bg-slate-800 rounded-full  p-3 w-16 h-16 shrink-0">
            <svg viewBox="0 0 24 24" className="text-white" astro-icon="bx:bxs-file-find"><path fill="currentColor" d="M6 22h12c.178 0 .348-.03.512-.074l-3.759-3.759A4.966 4.966 0 0 1 12 19c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5a4.964 4.964 0 0 1-.833 2.753l3.759 3.759c.044-.164.074-.334.074-.512V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2z" /><circle cx={12} cy={14} r={3} fill="currentColor" /></svg>
          </div>
          <div>
            <h3 className="font-semibold text-2xl">Park Domains and Monetize Traffic</h3>
            {/* <p className="text-slate-500 mt-2 leading-relaxed">Automatic sitemaps, RSS feeds, pagination and collections take the pain out of SEO and syndication. It just works!</p> */}
          </div>
        </div><div className="flex gap-4 items-center">
          <div className="mt-1 bg-slate-800 rounded-full  p-3 w-16 h-16 shrink-0">
            <svg viewBox="0 0 24 24" className="text-white" astro-icon="bx:bxs-user"><path fill="currentColor" d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z" /></svg>
          </div>
          <div>
            <h3 className="font-semibold text-2xl">Programmatic Domain Buying Services</h3>
            {/* <p className="text-slate-500 mt-2 leading-relaxed">Astro is an open source project powered by hundreds of contributors making thousands of individual contributions.</p> */}
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-tr from-gray-700 via-gray-900 to-black text-white p-4 mb-20 mt-20 rounded-3xl pt-10 m-2">
        <div className="text-white  space-y-6 p-6 rounded-xl-3xl p-12">
          <h1 className="text-white text-4xl md:text-4xl tracking-tighter font-bold drop-shadow-xl">
            Build your fence today
          </h1>
          <p className="text-slate-400 mt-4 text-2xl font-bold md:text-xl">
            Take control of your domain network and operate all your domains effortlessly, be it 10 domains or 100,000 domains.
          </p>
          <div className="flex mt-5 ">
            <a href="/register" className="rounded-xl text-2xl mb-10 text-center w-full transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-5 py-2.5 bg-white text-slate-800 border-2 border-transparent">Get Started
            </a></div>
        </div>

      </div>
    <Toaster/>
  </div>

}