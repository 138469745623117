import { Link } from "react-router-dom";

function Header({ festivals, festival, setFestival, user }) {
  return (
    <header className="overflow-x-hidden z-40   bg-white mt-5 w-full">
      <div className="flex m-auto rounded-xl bg-no-repeat bg-cover text-black">
        <nav
          className="flex place-items-center lg:justify-start items-center w-full mx-auto transition-all place-items-start"
          aria-label="Global"
        >
          <div className="overflow-hidden ">
            <div className="flex gap-4 overflow-hidden place-items-center m-3">
              <Link to="/">
                <img
                  src="https://cdn.shopify.com/s/files/1/0767/2836/9473/files/f-logo-100.png?v=1685542317"
                  width={25}
                  height={40}
                  alt="logo"
                />
              </Link>
              {/* <Link to="/playlist"><h1 className="line-clamp-1 bg-black rounded-3xl text-white pr-2 pl-2 text-xl tracking-tight font-sans font-medium pt-0 hover:bg-black bg-size-200 bg-pos-0 hover:text-white  cursor-pointer">
            make a playlist
          </h1></Link> */}
              {/* <Link to="https://festivals.ideas.userback.io/p/Ts6ZDevIaG" target="_blank"><h1 className="line-clamp-1 text-xl tracking-tight font-sans font-medium pt-0 hover:bg-black bg-size-200 bg-pos-0 hover:text-white  cursor-pointer">
            ideas
          </h1></Link> */}
              {/* <Link to="https://festivals.ideas.userback.io/p/Ts6ZDevIaG/roadmap" target="_blank"><h1 className="line-clamp-1 text-xl tracking-tight font-sans font-medium pt-0 hover:bg-black bg-size-200 bg-pos-0 hover:text-white  cursor-pointer">
            road to MVP
          </h1></Link> */}
              {/* <Link to="https://lollapalooza.rsvp" target="_blank"><h1 className="line-clamp-1 text-xl tracking-tight font-sans font-medium pt-0 hover:bg-black bg-size-200 bg-pos-0 hover:text-white  cursor-pointer">
            lollapalooza
          </h1></Link> */}
              {/* <Link to="https://glastonbury.rsvp" target="_blank"><h1 className="line-clamp-1 text-xl tracking-tight font-sans font-medium pt-0 hover:bg-black bg-size-200 bg-pos-0 hover:text-white  cursor-pointer">
            glastonbury
          </h1></Link> */}
              {/* <Link to="https://festivals.rsvp" target="_blank"><h1 className="line-clamp-1 text-xl tracking-tighter font-sans font-medium pt-0 hover:bg-black bg-size-200 bg-pos-0 hover:text-white  cursor-pointer">
            ultraland
          </h1></Link> */}
            </div>
          </div>
        </nav>
        <nav className="grid  mt-4 lg:justify-start items-center w-60 mx-auto transition-all place-items-start" aria-label="Global">
        <div className="flex gap-4 place-items-center">
   
          <select onChange={e => setFestival(e.target.value) } defaultValue={festival} className="select border-2 border-black w-min pt-2 pb-2 pr-2 pl-2 rounded-2xl items-center text-md tracking-tighter text-left font-sans font-bold pt-0 ">
          {festivals.map((name, i) => <option key={i}>{name}</option>)}
        </select>
        
        </div>
  
      </nav>
      </div>
      <hr className="my-2 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
    </header>
  );
}

export default Header;
