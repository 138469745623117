import { useParams } from "react-router-dom";
// import { titleCase } from "../utils"
// import { Spotify } from "react-spotify-embed"
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";

import { abbreviate } from "../utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { AddToCalendarEvent } from "react-add-to-calendar";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
} from "next-share";

function Artist({ festival, allArtists, descriptions, genre, album }) {
  const { slug } = useParams();
  let artist = allArtists.find((a) => a.slug === slug);

  let recommended = allArtists
    .filter((a) => a.genres.find((g) => artist?.genres.includes(g)))
    .filter((a) => a !== artist)
    .slice(0, 32);
  // let unfiltered = allArtists.slice(0, 20)

  let { name } = artist;
  // let albums = allArtists.find((a) => a.slug === slug);

  const spotifyId = artist.spotifyUrl?.match(/\w+$/)[0];
  const youtubeId = artist.youtubeUrl?.match(/channel\/(.*)/)[1];
  let [videos, setVideos] = useState([]);

  useEffect(() => {
    if (youtubeId) {
      fetch(
        `https://www.googleapis.com/youtube/v3/search?key=AIzaSyByjd4KLG11zzRkiTj28YCrc7lZuab-26M&channelId=${youtubeId}&part=snippet,id&order=date&maxResults=20`
      )
        .then((r) => r.json())
        .then((r) => {
          if (r.items) {
            setVideos(r.items);
          }
        });
    } else {
      setVideos([]);
    }
  }, [youtubeId]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [slug]);

  const [video] = videos;

  // return <div>
  //   <pre>{JSON.stringify(artist, null, 2)}</pre>
  // </div>

  return (
    <div className="grid">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Festivals.RSVP - {name} performing at {festival} Festival 2023
        </title>
        <meta name="description" content={descriptions[slug].join("\n")} />
        <link
          rel="canonical"
          href={`https://www.festivals.rsvp/artists/${encodeURI(name)}`}
        />
      </Helmet>
      <add-to-calendar-button 
  name="Sample Event"
  description="Play with me!"
  startDate="2023-12-04"
  startTime="10:15"
  endTime="17:45"
  timeZone="America/Los_Angeles"
  location="World Wide Web"
  icsFile="https://calendar.google.com/calendar/ical/c_59c0afb8527969ab56e7d7f38fdf8ccccf9c161d8d1c9c94ce50856dcaab3084%40group.calendar.google.com/public/basic.ics"
  subscribe
  options="'Apple','Google','iCal','Outlook.com','Yahoo'"
  listStyle="modal"
  buttonStyle="3d"
  buttonsList
  size="8"
></add-to-calendar-button>
      {/* --------- artist card ---------*/}
      <div className="breadcrumbs bg-tranparent m-2 text-black">
        <div className="grid lg:grid-cols-2  gap-6 text-black rounded-xl  bg-opacity-20 items-start bg-center  bg-cover  text-bold">
          {/* <Spotify spotifyToken={spotifyToken} trackUri={trackUri}/>  */}

          <div>
            <div className="text-black">
              {/* <div className="rounded-3xl p-6 mt-10 m-auto mb-10 w-5/6 aspect-square drop-shadow-md border-4 border-white" alt= {artist.albums} />  */}

              <div className="stat-value tracking-tighter lowercase text-2xl  mt-4 font-bold">
                {/* {abbreviate{(artist.youtubeScore + artist.facebookScore + artist.spotifyScore + artist.instagramScore)}} fans */}
              </div>
              <div className="grid-cols-auto absolute -ml-14">
                {/* <div className="grid m-auto items-center font-bold gap-2 mt-3 mb-5">
                {artist.spotifyScore !== 0 &&  <div className="text-3xl flex items-left space-x-8 rounded-xl drop-shadow-md gap-4">  {artist.spotifyUrl && <a href={artist.spotifyUrl} target="_blank" rel="noreferrer"><FaSpotify /></a>} </div> }
                {artist.youtubeScore !== 0 && <div className="text-3xl flex items-left space-x-8 rounded-xl drop-shadow-md gap-4">  <a href={artist.youtubeUrl} target="_blank" rel="noreferrer"><FaYoutube /></a></div> }
                {artist.facebookScore !== 0 && <div className="text-3xl flex items-left space-x-8 rounded-xl drop-shadow-md gap-4">  {artist.facebookUrl && <a href={artist.facebookUrl} target="_blank" rel="noreferrer"><FaFacebook /></a>} </div>} 
                {artist.instagramScore !== 0 && <div className="text-3xl flex items-left space-x-8 rounded-xl drop-shadow-md gap-4">  {artist.instagramUrl && <a href={artist.instagramUrl} target="_blank" rel="noreferrer"><FaInstagram /></a>} </div> }
                </div> */}
              </div>
              {/* <h1 className="text-xl tracking-tight font-bold font-sans lowercase pt-0 mt-2 ">Next up: {festival} 2023</h1> */}
              {/* <img src={artist.image} className="absolute w-min drop-shadow-md opacity-90 m-20 p-10 left-20  rotate-180" alt= {name} />  */}
              {/* <img src={artist.image} className="w-full onhover: animation-bounce" alt= {name} />  */}
              <div className="grid m-auto place-items-fit">
                <div className="flex">
                  <div className="text-5xl tracking-tighter font-sans font-bold pt-0 text-black pb-4">
                    {name}
                  </div>

                  <div className="font-bold text-2xl m-auto hover:animate-pulse tracking-tighter">
                    {abbreviate(
                      artist.youtubeScore +
                        artist.facebookScore +
                        artist.spotifyScore +
                        artist.youtubeScore
                    )}{" "}
                    fans
                  </div>
                </div>
              </div>

              <LazyLoadImage
                src={artist.image}
                className="w-full rounded-xl drop-shadow-lg"
                alt={name}
              />
              {/* <div className="font-bold text-xl tracking-tighter">Gender: {artist.gender}</div> */}
              <div className="space-x-2 mt-4">
                <FacebookShareButton
                  url={"https://festivals.rsvp"}
                  quote={"festivals.rsvp"}
                  hashtag={"#festivals"}
                >
                  <FacebookIcon size={34} round />
                </FacebookShareButton>
                {/* <LineShareButton
                          url={'https://festivals.rsvp'}
                          quote={'I just saw a bunch of kute kats on festivals.rsvp'}
                          hashtag={'#festivals'}>
                        <LineIcon size={42} round />
                        </LineShareButton> */}
                <RedditShareButton
                  url={"https://festivals.rsvp"}
                  quote={"festivals.rsvp"}
                  hashtag={"#festivals"}
                >
                  <RedditIcon size={34} round />
                </RedditShareButton>
                {/* <TelegramShareButton
                          url={'https://festivals.rsvp'}
                          quote={'I just saw a bunch of kute kats on festivals.rsvp'}
                          hashtag={'#festivals'}>
                        <TelegramIcon size={42} round />
                        </TelegramShareButton> */}
                <TwitterShareButton
                  url={"https://festivals.rsvp"}
                  quote={"festivals.rsvp"}
                  hashtag={"#festivals"}
                >
                  <TwitterIcon size={34} round />
                </TwitterShareButton>
              </div>
              {/* <div className="mt-6 p-2 m-2">
                <div className="font-bold text-3xl pb-4 m-auto tracking-tight">
                  Artist Representation
                </div>
                <div className="font-bold text-xl p-2 tracking-tighter">
                  Talent Agencies:
                </div>{" "}
                <div className="indent-4 m-2 text-lg tracking-tight">
                  {artist.agencies[0]} {artist.agencies[1]}
                </div>
                <div className="font-bold text-xl p-2 tracking-tighter">
                  Artist Management:
                </div>{" "}
                <div className="indent-4 m-2 text-lg tracking-tight">
                  {artist.management}
                </div>
              </div> */}
              <div className="grid lg:grid-cols-1 gap-5 pb-5">
                <div className="m-auto">
                  <div className="flex-1">
                    {/* <div className="flex-0 m-2 p-2 text-5xl flex items-left space-x-8 p-2 rounded-xl drop-shadow-md">
                  {artist.youtubeUrl && <a href={artist.youtubeUrl} target="_blank" rel="noreferrer"><FaYoutube /></a>}
                  {artist.spotifyUrl && <a href={artist.spotifyUrl} target="_blank" rel="noreferrer"><FaSpotify /></a>}
                  {artist.facebookUrl && <a href={artist.facebookUrl} target="_blank" rel="noreferrer"><FaFacebook /></a>}
                  {artist.instagramUrl && <a href={artist.instagramUrl} target="_blank" rel="noreferrer"><FaInstagram /></a>}
                </div> */}
                    {/* <div className="flex items-center space-x-2 text-xl">
                    <input disabled={comparing.length > 3 && !comparing.find(n => n === name)} key={name + comparing.length} onChange={() => toggleCompare(name)} type="checkbox" defaultChecked={comparing.includes(name)} className="checkbox checkbox-sm bg-white" />
                    <p>Compare</p>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* --------- descriptions ---------*/}

          <div className="p-2 m-2">
            <div className="font-bold text-3xl pt-6  m-auto hover:animate-pulse tracking-tighter m-2 ">
              Artist Bio
            </div>

            <h3 className="text-xl tracking-tight font-medium pt-0 mt-4 mb-4">
              {" "}
              {descriptions[slug]?.map((text, i) => (
                <p className="indent-8" key={i}>
                  {text}
                </p>
              ))}
            </h3>
            <iframe
              width="100%"
              className="pt-4 drop-shadow-xl"
              height="480"
              title="Spotify Embed"
              style={{ borderRadius: "12px" }}
              frameBorder="0"
              allowFullScreen
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture "
              src={`https://open.spotify.com/embed/artist/${spotifyId}?utm_source=oembed`}
            ></iframe>
<script defer src="https://cdn.commento.io/js/commento.js"></script>
<div id="commento"></div>
            <div className="text-xs breadcrumbs bg-tranparent "></div>
            {/* <h1 className="text-xl font-sans lowercase  pt-0 mt-10">{artist?.genres?.map((genre, i) => titleCase(genre)).join(", ")}</h1> */}
          </div>
          {/* <iframe width="100%" height="355" title="Spotify Embed" style={{ borderRadius: "12px" }} frameBorder="0" allowFullScreen allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" src={`https://open.spotify.com/embed/artist/${spotifyId}?utm_source=oembed`}></iframe> */}
        </div>
      </div>

      {/* {JSON.stringify(video?.id?.videoId)} */}

      <div className="text-xs breadcrumbs bg-tranparent m-2">
        {/* <div className="flex items-center space-x-2 text-xl">
                    <input disabled={comparing.length > 3 && !comparing.find(n => n === name)} key={name + comparing.length} onChange={() => toggleCompare(name)} type="checkbox" defaultChecked={comparing.includes(name)} className="checkbox checkbox-sm bg-white" />
                    <p>Compare</p>
                  </div> */}
      </div>
      <hr className="my-12 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
      {/* --------- video ---------*/}
      {video && (
        <h2 className="text-3xl tracking-tighter font-sans font-bold pt-0 mb-4 m-2">
          Featured Video
        </h2>
      )}
      {video && (
        <div
          key={video?.id?.videoId}
          className="aspect-video drop-shadow-md m-2 rounded-3xl m-2"
        >
          <iframe
            width="100%"
            height="500px"
            src={`https://www.youtube.com/embed/${video?.id?.videoId}`}
            autoPlay="1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            style={{ borderRadius: "20px", marginTop: "0px" }}
          />
        </div>
      )}

      {/* --------- social ---------*/}
      {video && (
        <hr className="h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
      )}
      {/* --------- artist grid ---------*/}
      <div className="m-2">
        {recommended !== null && (
          <h2 className="text-3xl pb-4 m-2 tracking-tighter font-sans font-bold mt-20 mb-4">
            Similar Artists
          </h2>
        )}
        <div className="grid grid-cols-2 m-2 lg:grid-cols-4 gap-10  drop-shadow-md outline-2 outline-accent-focus items-center ">
          {recommended.map((rec, i) => {
            let { slug, name } = rec;
            return (
              <Link
                to={`/artists/${slug}`}
                key={i}
                className="relative  rounded-3xl  "
              >
                <p className="absolute text-white line-clamp-2 text-xl tracking-tighter  font-bold text-center bottom-5 left-0 right-0 bg-opacity-50 z-10 text-xl drop-shadow-md">
                  {name}
                </p>
                <LazyLoadImage
                  src={rec.image}
                  alt={name}
                  className="w-full aspect-square rounded-3xl scale1"
                />
              </Link>
            );
          })}
        </div>
      </div>
      {/* <div className="mt-20 m-2">
      {unfiltered && <h2 className="text-4xl tracking-tighter font-sans font-bold pt-0">Trending Artists</h2>}
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-10 drop-shadow-xl outline-2 outline-accent-focus ">
        {unfiltered.map((rec, i) => {
          let { slug, name } = rec
          return <Link to={`/artists/${slug}`} key={i} className="relative mt-2 bg-black bg-opacity-20 rounded-3xl">
           <p className="absolute text-white line-clamp-2 text-xl tracking-tighter  font-bold text-center bottom-5 left-0 right-0 bg-opacity-50 z-10 text-xl drop-shadow-md">{name}</p>
            <img src={rec.image} alt={name} className="w-full aspect-square rounded-3xl  scale1 drop-shadow-md " />
          </Link>
        })}
      </div>
      </div> */}
    </div>
  );
}

export default Artist;
