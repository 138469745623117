import { useState } from "react"
import { Link } from "react-router-dom"
import { abbreviate, titleCase } from "../utils"
import { Helmet } from "react-helmet"

function Artists({ artists, schedule, festival }) {
  let rows = artists
  const [sortOptions, setSortOptions] = useState(["followers", false])
  const [sort, asc] = sortOptions

  rows = rows.sort((a, b) => {
    let v
    switch (sort) {
      case "name": v = a.name?.localeCompare(b.name); break
      case "popularity": v = b.popularity - a.popularity; break
      case "followers": v = b.followers.total - a.followers.total; break
      case "genres": v = a.genres.join().localeCompare(b.genres.join()); break
      case "albums": v = a.albums.join().localeCompare(b.albums.join()); break
      default: alert(sort)
    }
    return asc ? 0 - v : v
  })


  return <div className="">
        <Helmet>
      <meta charSet="utf-8" />
      <title>{festival} 2023 - All Artists</title>
      <meta name="description" content="{festival.artists}" />
      <link rel="canonical" href={`https://www.festivals.rsvp/artists/`} />
    </Helmet>
         <div><figure className="absolute mt-6 lg:mt-16 ">
              <svg className="absolute scale-[1.7] -bottom-12 left-0 right-0 mx-auto opacity-20 text-dark-500" viewBox="0 0 2494 439" fill="none" xmlns="http://www.w3.org/2000/svg">              
                <g mask="url(#mask0_1533_162)">
              
                </g>
                <defs>
                  <radialGradient id="paint0_radial_1533_162" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(1247 219.5) scale(1247 10266.4)">
                    <stop offset="0.348958" stopColor="currentColor">
                    </stop>
                    <stop offset={1} stopColor="currentColor" stopOpacity={0}>
                    </stop>
                  </radialGradient>
                </defs>
              </svg>
              <div className="relative w-58 h-28 sm:w-36 sm:h-36 bottom-16 -left-32 bg-gradient-to-br from-[#ADD3FF] to-blue-800 rounded-full animate-blob">
              </div>
              <div className="relative w-58 h-28 sm:w-36 sm:h-36 top-4 -right-32 bg-gradient-to-br from-[#CDAAFF] to-[#954DFF] rounded-full animate-blob animation-delay-3000">
              </div>
              <div className="relative w-20 h-20 sm:w-28 sm:h-28 bottom-4 -right-16 bg-gradient-to-br from-[#FFC1F1] to-[#D018A7] rounded-full animate-blob animation-delay-5000">
              </div>
              <div className="relative w-20 h-20 sm:w-28 sm:h-28 top-28 -left-10 bg-gradient-to-br from-[#FFFBEB] to-[#F8DA4B] rounded-full animate-blob animation-delay-2000">
              </div>
            </figure></div> 
    <div className="flex space-x-4 text-xl">
      {/* <div className=" p-2"> */}
      {/* <div className="pb-5 "><h2>Filter by Genre</h2></div>
        {genres.map((genre, i) => <div key={i} className="flex items-center font-bold space-x-2 pb-2">
          <input onChange={() => toggleFilter(genre)} type="checkbox" />
          <p>{titleCase(genre)}</p>
        </div>)}
      </div> */}
      <div className="p-2 flex-1 w-full text-xl mt-4">
        {/* <div className="pb-5 regular"><h3>{rows.length} artists found for {festival} 2023</h3></div> */}
        <table className="table table-auto w-full pt-10 drop-shadow-2xl text-xl">
          <thead className="font-bold text-xl">
            <tr>
              <td></td>
              <td>
                <button onClick={() => setSortOptions("name" === sort ? [sort, !asc] : ["name", false])} className="flex items-center justify-center space-x-2 w-full text-xl ">
                  <p className="text-left flex-1">Name</p>
                  {sort === "name" && <div className="text-lg">
     
                  </div>}
                </button>
              </td>
              <td>
                <button onClick={() => setSortOptions("popularity" === sort ? [sort, !asc] : ["popularity", false])} className="flex items-center justify-center space-x-2 w-full text-xl ">
                <p className="text-left flex-1">Popularity</p>
                  {sort === "popularity" && <div className="text-lg font-bold">
     
                  </div>}
                </button>
              </td>
  

              <td>
                <button onClick={() => setSortOptions("followers" === sort ? [sort, !asc] : ["followers", false])} className="flex items-center justify-center space-x-2 w-full text-xl ">
                <p className="text-left flex-1">Followers</p>
                  {sort === "followers" && <div className="text-lg">
          
                  </div>}
                </button>
              </td>
            </tr>
          </thead>
          <tbody>
            {rows.map((artist, i) => {
              let { slug, name, image, popularity, albums, genres, followers } = artist
              // let name = Object.keys(artists).find(key => artists[key] === artist)
              // let image = artist.images[artist.images.length - 1].url

              return <tr>
                <td><Link to={`/artists/${slug}`}><img src={image} alt={name} className="w-12 rounded-xl font-bold" /></Link></td>
                <td className="font-bold "><Link to={`/artists/${slug}`}>{name}</Link></td>
                <td>{popularity}</td>
                <td className="hidden md:table-cell"><p className="w-64 truncate text-clip overflow-hidden ">{albums.map(g => titleCase(g)).join(", ")}</p></td>

                <td className="hidden md:table-cell"><p className="w-64 truncate text-clip overflow-hidden ">{genres.map(g => titleCase(g)).join(",x ")}</p></td>
                <td>{abbreviate(followers.total)}</td>
              </tr>
            })}


          </tbody>
        </table>
      </div>
    </div>


  </div>
}

export default Artists