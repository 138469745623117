import SpotifyPlayer from "react-spotify-web-playback"
import { useState } from "react";

function Spotify({ spotifyToken, trackUri }) {
  const [play, setPlay] = useState(true)
  const authEndpoint = 'https://accounts.spotify.com/authorize';
  const clientId = "bd604d92cb254ae3a4883c53aef3d338";
  const redirectUri = window.location.href.match(/:300/) ? "http://localhost:3000" : "https://www.festivals.rsvp/playlist"

  const scopes = "streaming%20user-read-email%20user-read-private%20user-library-read%20user-library-modify%20user-read-playback-state%20user-modify-playback-state"

  return spotifyToken ? <SpotifyPlayer
    token={spotifyToken}
    showSaveIcon
    callback={state => !state.isPlaying && setPlay(false)}
    play={play}
    uris={trackUri ? trackUri : []}
    styles={{
      // activeColor: "#fff",
      // bgColor: "#333",
      // color: "#fff",
      // loaderColor: "#fff",
      // sliderColor: "#1cb954",
      // trackArtistColor: "#ccc",
      // trackNameColor: "#fff",
      // height: "55px",
    }}
  /> : <a onClick={() => localStorage.setItem('spotifyRedirect', window.location.href)} className="btn btn-primary" href={`${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes}&response_type=token&show_dialog=true`}>
    Login to Spotify
  </a>
}

export default Spotify