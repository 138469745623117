import { useEffect, useState } from "react"
import Spotify from "../components/Spotify"
import { FaPauseCircle, FaPlayCircle, FaQuestionCircle, FaStopCircle } from "react-icons/fa"

function PlaylistBuilder({ spotifyToken, setSpotifyToken }) {
  const [topTracks, setTopTracks] = useState([])
  const [user, setUser] = useState({})
  const [selectedTrack, setSelectedTrack] = useState()
  const [lastTrack, setLastTrack] = useState()
  const [suggestions, setSuggestions] = useState([])

  const f = async (endpoint, method = "GET", body = null) => {
    let options = {
      headers: {
        Authorization: `Bearer ${spotifyToken}`,
      },
      method
    }
    if (body) options.body = JSON.stringify(body)

    let r = await fetch(`https://api.spotify.com/${endpoint}`, options)
    return r.json()
  }

  // const p = (endpoint, body) => f(endpoint, "POST", body)

  function getSuggestions() {
    f(`v1/recommendations?limit=10&seed_tracks=${topTracks.slice(0,5).map(t => t.id).join(',')}`).then(s => setSuggestions(s.tracks))
    // f(`v1/recommendations?limit=5&seed_tracks=62t4UAR8vGbcA1O0saBUp0,0l7cfP9dZBrkkNkR7NXnQO,5o1mS67lEoLCeFobGIaNb2`).then(s => setSuggestions(s.tracks))
  }

  useEffect(() => {
    if (spotifyToken) {
      f(`v1/me`).then(setUser)
    }
    // f(`v1/recommendations?limit=5&seed_tracks=62t4UAR8vGbcA1O0saBUp0,0l7cfP9dZBrkkNkR7NXnQO,5o1mS67lEoLCeFobGIaNb2`).then(setTopTracks)
    // f(`v1/users/me/playlists`).then(setTopTracks)
    // eslint-disable-next-line
  }, [spotifyToken])

  useEffect(() => {
    if (user?.id && spotifyToken) {
      // f(`v1/users/${user.id}/playlists`).then(setTopTracks)
      // f(`v1/users/${user.id}/top/tracks?time_range=short_term&limit=5`).then(setTopTracks)
      f(`v1/me/tracks`).then(tt => setTopTracks(tt.items.map(i => i.track)))
    }
    // f(`v1/recommendations?limit=5&seed_tracks=62t4UAR8vGbcA1O0saBUp0,0l7cfP9dZBrkkNkR7NXnQO,5o1mS67lEoLCeFobGIaNb2`).then(setTopTracks)
    //
    // f(`v1/me`).then(setUser)
    // eslint-disable-next-line
  }, [spotifyToken, user])

  return <div>
    {spotifyToken ? <div>
      {selectedTrack && <div className="hidden"><iframe title="player" src={selectedTrack.preview_url} allow="autoplay" /></div>}
      <div className="text-3xl flex gap-3 m-3 border-black border rounded-full p-2 w-64">
        <div onClick={() => {
          setSelectedTrack(lastTrack)
        }} className="">
          <FaPlayCircle />
        </div>
        <div onClick={() => {
          setLastTrack(selectedTrack)
          setSelectedTrack()
        }} className="flex-1">
          <FaPauseCircle />
        </div>
        {selectedTrack && <a href={selectedTrack.external_urls.spotify} target="_blank" rel="noreferrer"><FaQuestionCircle /></a>}
        <div onClick={() => {
          setLastTrack(selectedTrack)
          setSelectedTrack()
        }} className="">
          <FaStopCircle />
        </div>
      </div>
      <button onClick={getSuggestions} className="btn btn-sm btn-primary">Get Suggestions</button>
      <div className="flex gap-4">
        {topTracks[0] && <div className="rounded p-4">
          <p className="text-xl font-bold p-2">Top Tracks</p>
          <div className="flex flex-wrap gap-3">
            {topTracks.map((track, i) => {
              return <div onClick={() => {
                setSelectedTrack(track)
                setLastTrack(track)
              }} key={i} className="w-32 truncate">
                <p>{track.name}</p>
                <img src={track.album.images[0].url} alt={track.name} className="w-32" />
              </div>
            })}
          </div>

        </div>}
        {suggestions[0] && <div className="rounded p-4 ">
          <p className="text-xl font-bold p-2">Suggested Tracks</p>
          <div className="flex flex-wrap gap-3">
            {suggestions.map((track, i) => {
              return <div onClick={() => {
                setSelectedTrack(track)
                setLastTrack(track)
              }} key={i} className="w-32 truncate">
                <p>{track.name}</p>
                <img src={track.album.images[0].url} alt={track.name} className="w-32" />
              </div>
            })}
          </div>

        </div>}
      </div>
      {/* <pre>{JSON.stringify(topTracks[0], null, 2)}</pre> */}
    </div> : <Spotify {...{ spotifyToken, setSpotifyToken }} />}
  </div>
}

export default PlaylistBuilder

/*1 Get your top 5 tracks from the last 30 days

```// Authorization token that must have been created previously. See : https://developer.spotify.com/documentation/web-api/concepts/authorization
const token = 'BQCJLCusw7uQmjitNkIo9Hs7TDd9bMpYKjJkdrUvDw4xDya4GSzjW8Xyk7eQMIzpLbr11jO2aI9pNlEAh29019mBp5C49fDZ7PkkKdJ-lB8fe4ZGUKk9AXClDkrJqYkOLb-k_Uh0ag2wyxDzY6GXdNPxy9k-__pZw5TuKxrgYSmoBpuaoyfPfoujSX0SdrD7UjdQPGFOvIC8RNrJQuS688Avtq6iCTsatggjOy4aGRrQNwKhd9auqZ9tisB_CZRogv5-DQuNCS7e912GTgPwVAvj9k26QriPdNQZyNC86htFirqLhK97Twl4u5YbdT8tytvuQDGfq5rmmNYsMW04lLmWJpSZ';
async function fetchWebApi(endpoint, method, body) {
  const res = await

async function getTopTracks(){
  // Endpoint reference : https://developer.spotify.com/documentation/web-api/reference/get-users-top-artists-and-tracks
  return (await fetchWebApi(
    '', 'GET'
  )).items;
}

const topTracks = await getTopTracks();
console.log(
  topTracks?.map(
    ({name, artists}) =>
      ${name} by ${artists.map(artist => artist.name).join(', ')}
  )
);```

2 Recommend 5 songs based on your top 5 tracks

```// Authorization token that must have been created previously. See : https://developer.spotify.com/documentation/web-api/concepts/authorization
const token = 'BQCJLCusw7uQmjitNkIo9Hs7TDd9bMpYKjJkdrUvDw4xDya4GSzjW8Xyk7eQMIzpLbr11jO2aI9pNlEAh29019mBp5C49fDZ7PkkKdJ-lB8fe4ZGUKk9AXClDkrJqYkOLb-k_Uh0ag2wyxDzY6GXdNPxy9k-__pZw5TuKxrgYSmoBpuaoyfPfoujSX0SdrD7UjdQPGFOvIC8RNrJQuS688Avtq6iCTsatggjOy4aGRrQNwKhd9auqZ9tisB_CZRogv5-DQuNCS7e912GTgPwVAvj9k26QriPdNQZyNC86htFirqLhK97Twl4u5YbdT8tytvuQDGfq5rmmNYsMW04lLmWJpSZ';
async function fetchWebApi(endpoint, method, body) {
  const res = await fetch(https://api.spotify.com/${endpoint}, {
    headers: {
      Authorization: Bearer ${token},
    },
    method,
    body:JSON.stringify(body)
  });
  return await res.json();
}

const topTracksIds = [
  '62t4UAR8vGbcA1O0saBUp0','5o1mS67lEoLCeFobGIaNb2','7iBZ739NZPq0DYm8pySNVz','4XzeThE3txvCBIrP40tj85','4HTNyzy75Hqxby5oCVlh87'
];

async function getRecommendations(){
  // Endpoint reference : https://developer.spotify.com/documentation/web-api/reference/get-recommendations
  return (await fetchWebApi(
    '
    ${topTracksIds.join(',')}, 'GET'
  )).tracks;
}

const recommendedTracks = await getRecommendations();
console.log(
  recommendedTracks.map(
    ({name, artists}) =>
      ${name} by ${artists.map(artist => artist.name).join(', ')}
  )
);```

3 Save the 10 songs in a playlist

```// Authorization token that must have been created previously. See : https://developer.spotify.com/documentation/web-api/concepts/authorization
const token = 'BQCJLCusw7uQmjitNkIo9Hs7TDd9bMpYKjJkdrUvDw4xDya4GSzjW8Xyk7eQMIzpLbr11jO2aI9pNlEAh29019mBp5C49fDZ7PkkKdJ-lB8fe4ZGUKk9AXClDkrJqYkOLb-k_Uh0ag2wyxDzY6GXdNPxy9k-__pZw5TuKxrgYSmoBpuaoyfPfoujSX0SdrD7UjdQPGFOvIC8RNrJQuS688Avtq6iCTsatggjOy4aGRrQNwKhd9auqZ9tisB_CZRogv5-DQuNCS7e912GTgPwVAvj9k26QriPdNQZyNC86htFirqLhK97Twl4u5YbdT8tytvuQDGfq5rmmNYsMW04lLmWJpSZ';
async function fetchWebApi(endpoint, method, body) {
  const res = await fetch(https://api.spotify.com/${endpoint}, {
    headers: {
      Authorization: Bearer ${token},
    },
    method,
    body:JSON.stringify(body)
  });
  return await res.json();
}

const tracksUri = [
  'spotify:track:62t4UAR8vGbcA1O0saBUp0','spotify:track:0l7cfP9dZBrkkNkR7NXnQO','spotify:track:5o1mS67lEoLCeFobGIaNb2','spotify:track:6nWxBEq8mQ4z3h40RnjxG9','spotify:track:7iBZ739NZPq0DYm8pySNVz','spotify:track:6WarP4oMloYg0Z8H8IHeDq','spotify:track:4XzeThE3txvCBIrP40tj85','spotify:track:6HXdL92qb3doVllJ5yChnd','spotify:track:4HTNyzy75Hqxby5oCVlh87','spotify:track:32uzMvtg0JKDKZzg6KhHoZ'
];
const user_id = 'andrewcwang';

async function createPlaylist(tracksUri){
  return await fetchWebApi(
    v1/users/${user_id}/playlists, 'POST', {
      "name": "My recommendation playlist",
      "description": "Playlist created by the tutorial on developer.spotify.com",
      "public": false
  }).then(playlist => {
    fetchWebApi(
      v1/playlists/${playlist.id}/tracks?uris=${tracksUri.join(',')},
      'POST'
    );
    return playlist;
  })
}

const createdPlaylist = await createPlaylist(tracksUri);
console.log(createdPlaylist.name, createdPlaylist.id);```

4 Listen to the songs right here right now

```const playlistId = '7JJ4j6mGqXyiKSCMs4YANI';

<iframe
  title="Spotify Embed: Recommendation Playlist "
  src={https://open.spotify.com/embed/playlist/7JJ4j6mGqXyiKSCMs4YANI?utm_source=generator&theme=0}
  width="100%"
  height="100%"
  style={{ minHeight: '360px' }}
  frameBorder="0"
  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
  loading="lazy"
/>```
*/

/*
create playlist
POST
/users/{user_id}/playlists
name: "Your Coolest Playlist"
public: true
collaborative: false
description: "a playlist"
*/

/*
add tracks
POST
/playlists/{playlist_id}/tracks
playlist_id: "3cEYpjA9oz9GiPac4AsH4n"
position: 0
uris: "spotify:track:4iV5W9uYEdYUVa79Axb7Rh, spotify:track:1301WleyT98MSxVHPZCA6M, spotify:episode:512ojhOuo1ktJprKbVcKyQ"
*/