import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import { ReactAddToCalendar } from "react-add-to-calendar";
import { UserbackProvider } from '@userback/react';

const root = ReactDOM.createRoot(document.getElementById("root"))
ReactDOM.createRoot(document.getElementById('root')).render(
    <UserbackProvider token="40677|81741|sjKpEe9jMWX1MTPvJUaOdxFyc">
      <App />
    </UserbackProvider>
  );
root.render(<App />)