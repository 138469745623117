import { useParams } from "react-router-dom"

function Blog({posts}){
  const { id } = useParams()
  const post = posts.find(p => p.id === id) || {}
  const sections = JSON.parse(post?.sections || "[]")

  return <div>

      <h1 className="text-2xl font-bold text-center p-3">{post.title}</h1>
      <div className="w-96 p-4 float-left">
        <img src={post.image_url} alt={post.slug}></img>
      </div>
      {sections.map((section, i) => <div key={i} className="p-2">
        <section>
          <h2 className="text-xl font-bold">{section.title}</h2>
          <p>{section.content}</p>
        </section>

      </div>)}


    {/* <pre>{JSON.stringify(posts, null, 2)}</pre> */}

  </div>
}

export default Blog