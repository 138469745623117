import md5 from "crypto-js/md5"
const root = process.env.NEXT_PUBLIC_APP_URL

export function f(url, opts = {}, ...args) {
  let user = JSON.parse(localStorage.getItem("mtdrUser") || "{}");
  if (user?.token) {
    opts.headers = opts.headers || {};
    opts.headers["Authorization"] = `Bearer ${user.token}`;
  }
  return fetch(`${root}${url}`, opts, ...args)
    .then((r) => r.json())
}

export function abbreviate(n) {
  n = Number(n || 0);
  if (n < 1e3) return +(n / 1e3).toFixed(0) + "K";
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(0) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(0) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(0) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}

export function titleCase(str) {
  return (str || "").replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

// const root = "https://www.houseparty.net/api"

// export function f(url, opts = {}, ...args) {
//   return fetch(`${root}${url}`, opts, ...args)
//     .then((r) => r.json())
// }

export function compareLink(names) {
  return `/compare/${names.map(x => x.replace(/\W+/g, '_')).join('-vs-')}`
}
