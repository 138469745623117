import { BrowserRouter, Route, Routes } from "react-router-dom";
import Artists from "./pages/Artists";
import Stages from "./pages/Stages";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
// import artists from "./coachella.json"
import schedule from "./schedule.json"
import descriptions from "./descriptions.json"
import Artist from "./pages/Artist";
import Compare from "./pages/Compare";
import toast, { Toaster } from 'react-hot-toast';
import { Analytics } from '@vercel/analytics/react';
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Livestream from "./pages/Livestream";
import Replay from "./pages/Replay";
import CompareBox from "./components/CompareBox";
import festivals from "./festivals.json";
import allArtists from "./artists.json";
import { useEffect, useState } from "react"
// import ReactGA from 'react-ga';
import './App.css';
import PlaylistBuilder from "./pages/PlaylistBuilder";
import Blog from "./pages/Blog";
import { f } from "./utils";
import Blogs from "./pages/Blogs";
// import Festivals from "./pages/Festival";
import Contact from "./pages/contact";
import FestivalList from "./components/FestivalSelector";





const App = () => {
  const [spotifyToken, setSpotifyToken] = useState()
  const [festival, setFestival] = useState("Outside Lands")
  const [posts, setPosts] = useState([])
  const [comparing, setComparing] = useState([])
  const toggleCompare = name => {
    if(comparing.includes(name)){
      setComparing(comparing.filter(n => n !== name))
    } else {
      setComparing([...comparing, name])
    }
  }
  const slugs = festivals.find(f => f.name === festival).artists
  const artists = slugs.map(slug => allArtists.find(a => a.slug === slug))

  let pageProps = {
    festivals: festivals.map(f => f.name),
    festival,
    setFestival,
    artists,
    schedule,
    toast,
    descriptions,
    allArtists,
    posts,
    comparing, setComparing, toggleCompare,
    spotifyToken, setSpotifyToken,
  };

  // ReactGA.initialize('Your Unique ID');
  // ReactGA.pageview(window.location.pathname);

  useEffect(() => {
    f(`/blogs`).then(setPosts)
  }, [])

  return (

    <div className="container m-auto p-2">
      <BrowserRouter>
      
        <Header {...pageProps} />
        {comparing.length > 0 && <CompareBox {...pageProps}/>}

        <Routes>
          <Route path="/artists" element={<Artists {...pageProps}  />} />
          <Route path="/artists/:slug" element={<Artist {...pageProps} />} />
          <Route path="/stages" element={<Stages {...pageProps} />} />
          <Route path="/privacy" element={<Privacy {...pageProps} />} />
          <Route path="/terms" element={<Terms {...pageProps} />} />
          <Route path="/livestream" element={<Livestream {...pageProps} />} />
          <Route path="/replay" element={<Replay {...pageProps} />} /> 
          <Route path="/playlist" element={<PlaylistBuilder {...pageProps} />} />
          <Route path="/compare/:comparing" element={<Compare {...pageProps} />} />
          <Route path="/blog" element={<Blogs {...pageProps} />} />
          <Route path="/blog/:id" element={<Blog {...pageProps} />} />
          <Route path="/" element={<Home {...pageProps} />} />
          <Route path="contact" element={<Contact {...pageProps} />} />

        </Routes>
        <FestivalList {...pageProps} />
        <Footer {...pageProps} />
      </BrowserRouter >
      <Toaster />
      <Analytics />
    </div>
  );
};

export default App;
