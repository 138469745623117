import { Link } from "react-router-dom"

function Blogs({posts}){
  return <div>

    {posts.map((post, i) => <Link key={i} to={`/blog/${post.id}`} className="flex items-center text-black">
      <div className="p-2 w-1/6">
        <img src={post.image_url} alt={post.title} className=""></img>
      </div>
      <div className="p-2 w-5/6">
        <div>
          <h2 className="text-2xl">{post.title}</h2>
          <h2 className="">{post.description}</h2>
        </div>
      </div>

    </Link>)}
    {/* <pre>{JSON.stringify(posts, null, 2)}</pre> */}

  </div>
}

export default Blogs