import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { compareLink, titleCase } from "../utils"

let keys = ["name", "x", "y", "z", "a"]

// function d(s) {
//   switch (s) {
//     case 1: return "Low"
//     case 2: return "Medium"
//     case 3: return "High"
//     default: return s
//   }
// }

function Compare({ allArtists, metrics, setComparing }) {
  const { comparing } = useParams()
  let tokens = comparing.split('-vs-')
  let names = [...new Set(allArtists.map(a => a.name).filter(p => tokens.includes(p.replace(/\W+/g, '_'))))]
  return <div>
    <div className={`grid grid-cols-${names.length + 1} bg-gray-200 shadow-md shadow-gray-500 my-3`}>
      <div className="p-2 items-center text-center text-xl font-bold flex justify-center">
        <button disabled={names.length > 3} onClick={() => setComparing(names)} className="btn btn-primary">Add a Artist</button>
      </div>
      {names.map((name, i) => {
        let artist = allArtists.find(a => a.name === name)

        return <div key={i} className="p-2 items-center text-center text-xl font-bold relative aspect-square overflow-hidden">
        {names.length > 2 && <Link to={compareLink(names.filter(n => n !== name))} className="absolute top-4 left-4">
          <svg fill="#000000" viewBox="0 0 512 512" className="w-4 h-4"><path d="M391 345l-46 46-89-90-89 90-46-46 90-89-90-89 46-46 89 90 89-90 46 46-90 89z"></path></svg>
        </Link>}
        <img src={artist.image} alt={name}/>
      </div>
      })}
      <div/>
      {names.map((name, i) => {
        let artist = allArtists.find(a => a.name === name)
        return <div key={i} className="p-2 text-center text-xl font-bold">
        <Link to={`/artists/${artist.slug}`}>{name}</Link>
      </div>
      })}
    </div>

    <div className=" bg-gray-200 shadow-md shadow-gray-500 my-3">
      <div className={` p-4 text-xl font-bold`}>
        At A Glance
      </div>
      {keys.map((key, j) => <div key={j} className={`grid grid-cols-${names.length + 1}`}>
        <p className="p-4 font-bold">{titleCase(key)} </p>
        {/* {[...new Array(names.length)].map((_, i) => <p key={i} className="p-4">{d(metrics.find(m => m.name === names[i])[key])}</p>)} */}
      </div>)}
    </div>



  </div>
}

export default Compare